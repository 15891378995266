/* ==========================================================================
  main.js
========================================================================== */

/**
 * Init dropdown-menus
 */
var dropdown_menus = document.querySelectorAll('.js-navigation');
for (var i = 0; i < dropdown_menus.length; i++) {
  component_dropdown_menu({
    desktop_min_width: 890,
    menu: dropdown_menus[i]
  });
}

/**
 * Init mobile menu
 */
component_mobile_menu({
  menu:     document.querySelector('.js-mobile-menu'),
  site:     document.querySelector('.js-page'),
  toggles:  document.querySelectorAll('.js-menu-toggle')
});

/**
 * Init Responsive videos
 */
fitvids();

/**
 * Init polyfill object-fit for lazyloaded
 */
if (typeof objectFitPolyfill === "function") {
  document.addEventListener('lazybeforeunveil', function(e){
    objectFitPolyfill();
    e.target.addEventListener('load', function() {
      objectFitPolyfill();
    });
  });
}

/**
 * Init lightbox
 */
if (document.querySelectorAll('.lightbox').length) {
  try {
    new Tobi({
      // variables from script localization in register-assets.php
      navLabel: [theme_strings.prev, theme_strings.next],
      closeLabel: theme_strings.close,
      loadingIndicatorLabel: theme_strings.loading,
      captionsSelector: 'self',
      captionAttribute: 'data-caption',
      zoom: false,
    });
  } catch (e) {
    console.log(e);
  }
}

if (document.querySelectorAll('.home-slider__inner').length) {
    var slider = tns({
      container: '.home-slider__inner',
      items: 1,
      slideBy: 'page',
      autoplay: true,
      autoplayButtonOutput: false,
      controls: false,
      nav: true,
      navPosition: 'bottom',
    });
}

if (document.querySelectorAll('.last-posts__slider__inner').length) {
    var slider = tns({
      container: '.last-posts__slider__inner',
      items: 1,
      slideBy: 'page',
      autoplay: true,
      autoplayButtonOutput: false,
      controls: false,
      nav: true,
      navPosition: 'bottom',
      prevButton: '#slider-lastpost-prev',
      nextButton: '#slider-lastpost-next',
      responsive: {
        720: {
          controls: true,
        }
      }
    });
}

if (document.querySelectorAll('.related-posts__slider__inner').length) {
    var slider = tns({
      container: '.related-posts__slider__inner',
      items: 1,
      slideBy: 'page',
      autoplay: true,
      autoplayButtonOutput: false,
      controls: true,
      nav: false,
      gutter: 0,
      prevButton: '#related-posts-prev',
      nextButton: '#related-posts-next',
      responsive: {
        720: {
          items: 2,
          gutter: 25,
        },
        1100: {
          items: 3,
          gutter: 50,
        },
      }
    });
}

if (document.querySelectorAll('.reference-gallery__inner').length) {
    var slider = tns({
      container: '.reference-gallery__inner',
      items: 1,
      slideBy: 'page',
      autoplay: true,
      autoplayButtonOutput: false,
      controls: true,
      nav: true,
      navPosition: 'bottom',
      prevButton: '#gallery-prev',
      nextButton: '#gallery-next',
    });
}
